<template>
  <v-app>
    <div
      v-if="!showSignupOnboarding"
      class="help-button pointer text-button d-none d-md-flex pa-2 c_white_1--text align-center justify-center"
      @click="openHelpCenter"
    >
      {{ $trans("help") }}
      <v-icon class="ml-2" color="c_white_1"> $help</v-icon>
    </div>
    <template v-if="!isExpiredPlanView && !showSignupOnboarding">
      <dashboard-core-drawer v-if="$route.name !== 'plugins_mobile_editor'" />
      <dashboard-core-app-bar v-if="$route.name !== 'plugins_mobile_editor'" />
      <dashboard-core-view :has-any-past-due-invoice="hasAnyPastDueInvoice" />
      <preview-asset />
    </template>

    <transition name="fade">
      <c-signup-onboarding
        v-if="showSignupOnboarding && !isExpiredPlanView && loggedUser"
        :tenant-email="loggedUser.email"
      />
    </transition>

    <v-dialog
      v-model="forcePlanView"
      content-class="white"
      fullscreen
      persistent
    >
      <calendesk-plans
        v-if="forcePlanView"
        key="initial-plans"
        :is-expired="isExpiredPlanView"
      />
    </v-dialog>
    <v-dialog
      v-model="isPastDuePlanView"
      content-class="white"
      fullscreen
      persistent
    >
      <past-due-subscription :account-blocked="isAccountBlocked" />
    </v-dialog>
    <event-dialog v-model="showEventModal" :raw-event="currentEventData" />
    <create-booking-payment-transaction-dialog />
    <new-client-modal />
    <event-preview-dialog
      v-model="showEventPreviewModal"
      :raw-event="currentEventPreviewData"
    />
    <send-customer-notification-dialog
      v-model="showCustomerNotificationModal"
      :payload="currentCustomerNotificationData"
    />
    <manage-user-subscription-dialog
      v-model="showManageUserSubscriptionModal"
      :payload="currentManageUserSubscriptionModalData"
    />
    <manage-simple-store-product-dialog
      v-model="showManageSimpleStoreProductModal"
      :payload="currentManageSimpleStoreProductModalData"
    />
    <app-dialog :component-list="dialogList" />
    <confirm-dialog :confirm-dialog-list="confirmDialogList" />
    <snackbar />
    <reload-snackbar version-type="admin_version" />
  </v-app>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Snackbar from "@/lib/calendesk-js-library/components/Snackbar";

import confirmDialogList from "@/components/ConfirmDialogs/confirmDialogList";
import dialogList from "@/components/Dialogs/dialogList";
import ReloadSnackbar from "@/lib/calendesk-js-library/components/ReloadSnackbar";
import registerModules from "@/store/registerModules";
import planActions from "@/calendesk/mixins/planActions";
import urls from "@/calendesk/mixins/urls";
import dialogSize from "@/lib/calendesk-js-library/components/dialogs/dialogSize";
import sharedDialogTypes from "@/lib/calendesk-js-library/components/dialogs/sharedDialogTypes";
import { getItem } from "@/lib/calendesk-js-library/tools/localStorageManager";
import CSignupOnboarding from "@/components/CSignUpOnboarding/CSignUpOnboarding";
import EventDialog from "@/views/dashboard/pages/Calendar/components/EventDialog.vue";
import { EventData } from "@/views/dashboard/pages/Calendar/components/EventData";
import { pushEvent } from "@/lib/calendesk-js-library/tools/helpers";
import { warningNotification } from "@/lib/calendesk-js-library/tools/notification";
import PastDueSubscription from "@/lib/calendesk-js-library/components/PastDueSubscription";
import EventPreviewDialog from "@/components/Dialogs/EventPreviewDialog.vue";
import CreateBookingPaymentTransactionDialog from "@/components/Forms/CreateBookingPaymentTransactionDialog.vue";
import NewClientModal from "@/views/dashboard/pages/Calendar/components/NewClientModal.vue";
import CalendeskPlans from "@/views/dashboard/pages/Plans/CalendeskPlans.vue";
import SendCustomerNotificationDialog from "@/components/Dialogs/SendCustomerNotificationDialog.vue";
import ManageUserSubscriptionDialog from "@/components/Dialogs/ManageUserSubscriptionDialog.vue";
import ManageSimpleStoreProductDialog from "@/components/Dialogs/ManageSimpleStoreProductDialog.vue";

export default {
  name: "DashboardIndex",
  components: {
    ManageSimpleStoreProductDialog,
    ManageUserSubscriptionDialog,
    SendCustomerNotificationDialog,
    CalendeskPlans,
    NewClientModal,
    CreateBookingPaymentTransactionDialog,
    EventPreviewDialog,
    PastDueSubscription,
    EventDialog,
    CSignupOnboarding,
    ReloadSnackbar,
    Snackbar,
    DashboardCoreAppBar: () => import("../dashboard/components/AppBar/AppBar"),
    DashboardCoreDrawer: () => import("./components/core/Drawer/Drawer"),
    DashboardCoreView: () => import("./components/core/View"),
    AppDialog: () =>
      import("@/lib/calendesk-js-library/components/dialogs/AppDialog"),
    ConfirmDialog: () =>
      import(
        "@/lib/calendesk-js-library/components/confirmDialogs/ConfirmDialog"
      ),
    PreviewAsset: () =>
      import("@/lib/calendesk-js-library/components/PreviewAsset"),
  },
  mixins: [planActions, urls],
  beforeRouteEnter(to, from, next) {
    registerModules();
    next();
  },
  data() {
    return {
      showEventModal: false,
      showEventPreviewModal: false,
      showCustomerNotificationModal: false,
      currentCustomerNotificationData: null,
      showManageUserSubscriptionModal: false,
      currentManageUserSubscriptionModalData: null,
      showManageSimpleStoreProductModal: false,
      currentManageSimpleStoreProductModalData: null,
      currentEventData: new EventData(),
      currentEventPreviewData: new EventData(),
      configurationStatus: false,
      confirmDialogList,
      dialogList,
      forcePlanView: false,
      isExpiredPlanView: false,
      isPastDuePlanView: false,
      isAccountBlocked: false,
      hasAnyPastDueInvoice: false,
      showSignupOnboarding: false,
    };
  },
  computed: {
    ...mapGetters({
      getGlobalErrorCode: "setup/getGlobalErrorCode",
      notificationUsage: "notification/getUsage",
    }),
  },
  watch: {
    adminConfiguration() {
      this.reloadOnboardingStatus();
    },
    currentPlan: {
      handler() {
        this.checkCurrentPlanRelations();
      },
      deep: true,
    },
    hasAnyPastDueInvoice(val) {
      this.setHasAnyPastDueInvoice(val);
    },
    showEventModal(val) {
      this.setBookingCalendarState(val);
    },
  },
  mounted() {
    this.$root.$on("openPlanView", this.openPlanView);
    this.$root.$on("goToPluginsStore", this.goToPluginsStore);
    this.$root.$on("closePlanView", this.closePlanView);
    this.$root.$on(
      "openFeatureNotAvailableDialog",
      this.openFeatureNotAvailableDialog,
    );
    this.$root.$on(
      "openPackageNotAvailableDialog",
      this.openPackageNotAvailableDialog,
    );
    this.$root.$on("openContactForm", this.openContactForm);
    this.$root.$on("openEventModal", this.openEventModal);
    this.$root.$on("closeEventModal", this.closeEventModal);

    this.$root.$on("openEventPreviewModal", this.openEventPreviewModal);
    this.$root.$on("closeEventPreviewModal", this.closeEventPreviewModal);

    this.$root.$on(
      "openCustomerNotificationModal",
      this.openCustomerNotificationModal,
    );
    this.$root.$on(
      "closeCustomerNotificationModal",
      this.closeCustomerNotificationModal,
    );

    this.$root.$on(
      "openManageUserSubscriptionModal",
      this.openManageUserSubscriptionModal,
    );
    this.$root.$on(
      "closeManageUserSubscriptionModal",
      this.closeManageUserSubscriptionModal,
    );

    this.$root.$on(
      "openManageSimpleStoreProductModal",
      this.openManageSimpleStoreProductModal,
    );
    this.$root.$on(
      "closeManageSimpleStoreProductModal",
      this.closeManageSimpleStoreProductModal,
    );

    this.$root.$on("refreshBookings", this.refreshBookings);
    this.$root.$on("openPastDueView", this.openPastDueView);
  },
  beforeDestroy() {
    this.$root.$off("openPlanView", this.openPlanView);
    this.$root.$off("closePlanView", this.closePlanView);
    this.$root.$off("goToPluginsStore", this.goToPluginsStore);
    this.$root.$off("closeEventModal", this.closeEventModal);
    this.$root.$off(
      "openFeatureNotAvailableDialog",
      this.openFeatureNotAvailableDialog,
    );
    this.$root.$off(
      "openPackageNotAvailableDialog",
      this.openPackageNotAvailableDialog,
    );
    this.$root.$off("openContactForm", this.openContactForm);
    this.$root.$off("openEventModal", this.openEventModal);

    this.$root.$off("openEventPreviewModal", this.openEventPreviewModal);
    this.$root.$off("closeEventPreviewModal", this.closeEventPreviewModal);

    this.$root.$off(
      "openCustomerNotificationModal",
      this.openCustomerNotificationModal,
    );
    this.$root.$off(
      "closeCustomerNotificationModal",
      this.closeCustomerNotificationModal,
    );

    this.$root.$off(
      "openManageUserSubscriptionModal",
      this.openManageUserSubscriptionModal,
    );
    this.$root.$off(
      "closeManageUserSubscriptionModal",
      this.closeManageUserSubscriptionModal,
    );

    this.$root.$off(
      "openManageSimpleStoreProductModal",
      this.openManageSimpleStoreProductModal,
    );
    this.$root.$off(
      "closeManageSimpleStoreProductModal",
      this.closeManageSimpleStoreProductModal,
    );

    this.$root.$off("refreshBookings", this.refreshBookings);
    this.$root.$off("openPastDueView", this.openPastDueView);
  },
  created() {
    this.$store.commit("setup/SET_PRELOADER", false);

    if (!this.getGlobalErrorCode) {
      this.checkCurrentPlanRelations();
    }
  },
  methods: {
    ...mapActions({
      refreshBookings: "booking/refreshBookings",
      fetchRequiredAppComponents: "setup/fetchRequiredAppComponents",
      setHasAnyPastDueInvoice: "setup/setHasAnyPastDueInvoice",
      setBookingCalendarState: "booking/setBookingCalendarState",
    }),
    checkCurrentPlanRelations() {
      if (
        !this.currentPlan ||
        (!this.currentPlan.on_trial &&
          this.$moment(this.currentPlan.current_period_end).isBefore()) ||
        this.currentPlan.slug === this.$helpers.plans.CD_BASE_PAUSED
      ) {
        this.openPlanView(true);
      } else {
        if (
          this.currentPlan &&
          this.currentPlan.subscription &&
          this.currentPlan.subscription.stripe_status === "past_due" &&
          this.currentPlan.subscription.updated_at
        ) {
          const isExpired = this.$moment(
            this.currentPlan.subscription.updated_at,
          )
            .add(7, "days")
            .isBefore(this.$moment());

          if (isExpired) {
            this.isPastDuePlanView = true;
            this.isAccountBlocked = true;
          } else {
            this.hasAnyPastDueInvoice = true;
          }
        } else {
          this.isPastDuePlanView = false;
          this.isAccountBlocked = false;
          this.hasAnyPastDueInvoice = false;
        }

        this.reloadOnboardingStatus();
        this.checkNotifications();
      }
    },
    checkNotifications() {
      if (
        this.$route.name === "dashboard" &&
        !this.showSignupOnboarding &&
        this.currentPlan &&
        !this.currentPlan.on_trial &&
        this.notificationUsage &&
        (this.notificationUsage.email <= 50 || this.notificationUsage.sms <= 10)
      ) {
        const canceled = getItem("warning_nroon", false, true);

        let skipShowing = false;
        if (canceled) {
          const month = this.$moment.duration(1, "month");
          const monthAgo = this.$moment().subtract(month);
          const canceledAt = this.$moment(
            canceled,
            this.$helpers.dateTimeFormat,
          );

          skipShowing = canceledAt.isAfter(monthAgo);
        }

        if (!skipShowing) {
          setTimeout(() => {
            warningNotification(
              "notifications_running_out_of_notifications",
              "warning_nroon",
              {},
              { name: "notifications", params: { tab: "settings" } },
              this.$trans("go_to_notifications"),
            );
          }, 500);
        }
      }
    },
    reloadOnboardingStatus() {
      this.showSignupOnboarding =
        this.adminConfiguration &&
        this.adminConfiguration.onboarding_admin &&
        this.adminConfiguration.onboarding_admin.step;
    },
    openFeatureNotAvailableDialog(data) {
      this.openDialog({
        type: sharedDialogTypes.FEATURE_NOT_AVAILABLE_DIALOG,
        title: null,
        size: dialogSize.LARGE,
        data,
      });
    },
    openPackageNotAvailableDialog(data) {
      this.openDialog({
        type: sharedDialogTypes.PACKAGE_NOT_AVAILABLE_DIALOG,
        title: null,
        size: dialogSize.LARGE,
        data,
      });
    },
    closePlanView(redirect) {
      this.forcePlanView = false;
      this.isPastDuePlanView = false;
      this.isAccountBlocked = false;
      this.isExpiredPlanView = false;
      if (redirect && this.$route.name !== "dashboard") {
        this.$router.push({ name: "dashboard" });
      }

      this.fetchRequiredAppComponents();
    },
    openPlanView(expired) {
      pushEvent("openPlanView", {
        expired: expired,
      });
      this.forcePlanView = true;
      this.isExpiredPlanView = expired;
    },
    openPastDueView(isAccountBlocked) {
      pushEvent("openPastDueView", {
        isAccountBlocked: this.isAccountBlocked,
      });

      this.isPastDuePlanView = true;
      this.isAccountBlocked = isAccountBlocked;
    },
    goToPluginsStore() {
      this.$router.push({ name: "billing", params: { tab: "subscription" } });
    },
    openHelpCenter() {
      pushEvent("openHelpCenter");
      const locale = localStorage.getItem("locale").toLowerCase();

      if (locale === "pl") {
        window.open(`https://calendesk.com/pl/help/`, "_blank");
      } else {
        window.open(`https://calendesk.com/help/`, "_blank");
      }
    },
    openContactForm() {
      pushEvent("openContactForm");
      if (getItem("locale", false) === "pl") {
        window.open("https://support-pl.calendesk.net/kontakt", "_blank");
      } else {
        window.open("https://support-en.calendesk.net/contact", "_blank");
      }
    },
    closeEventModal(reloadCalendar) {
      this.currentEventData = new EventData();

      if (reloadCalendar === true) {
        this.refreshBookings();
      }

      this.showEventModal = false;
    },
    openEventModal(eventData) {
      this.currentEventData = eventData || new EventData();
      this.showEventModal = true;
    },
    closeEventPreviewModal(reloadCalendar) {
      this.showEventPreviewModal = false;
      this.currentEventPreviewData = new EventData();

      if (reloadCalendar === true) {
        this.refreshBookings();
      }
    },
    openEventPreviewModal(eventData) {
      this.currentEventPreviewData = eventData || new EventData();
      this.showEventPreviewModal = true;
    },
    closeCustomerNotificationModal() {
      this.showCustomerNotificationModal = false;
      this.currentCustomerNotificationData = null;
    },
    openCustomerNotificationModal(data) {
      this.showCustomerNotificationModal = true;
      this.currentCustomerNotificationData = data;
    },
    closeManageUserSubscriptionModal() {
      this.showManageUserSubscriptionModal = false;
      this.currentManageUserSubscriptionModalData = null;
    },
    openManageUserSubscriptionModal(data) {
      this.showManageUserSubscriptionModal = true;
      this.currentManageUserSubscriptionModalData = data;
    },
    closeManageSimpleStoreProductModal() {
      this.showManageSimpleStoreProductModal = false;
      this.currentManageSimpleStoreProductModalData = null;
    },
    openManageSimpleStoreProductModal(data) {
      this.showManageSimpleStoreProductModal = true;
      this.currentManageSimpleStoreProductModalData = data;
    },
  },
};
</script>

<style lang="scss" scoped>
.help-button {
  position: fixed;
  border-radius: 0 0 8px 8px;
  top: 0;
  right: 60px;
  width: 150px;
  height: 30px;
  background-color: var(--v-primary-base);
  color: var(--v-c_white_1-base);
  z-index: 9;
}
</style>
