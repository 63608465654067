import { pushEvent } from "@/lib/calendesk-js-library/tools/helpers";

export default {
  data() {
    return {
      stripeApiKey: process.env.VUE_APP_STRIPE_API_KEY,
      paymentIntentClientSecret: null,
      stripe: null,
      show3DSDialog: false,
      stripe3DsSrc: null,
    };
  },
  mounted() {
    window.addEventListener("message", this.receiveMessage);
  },
  beforeDestroy() {
    window.removeEventListener("message", this.receiveMessage);
  },
  methods: {
    loadStripe() {
      return new Promise((resolve, reject) => {
        if (typeof Stripe === "function") {
          this.stripe = Stripe(this.stripeApiKey); // eslint-disable-line no-undef
          resolve();
        } else {
          const src = "https://js.stripe.com/v3/";
          this.$loadScript(src)
            .then(() => {
              this.stripe = Stripe(this.stripeApiKey); // eslint-disable-line no-undef
              resolve();
            })
            .catch((error) => {
              reject(error);
            });
        }
      });
    },
    open3DsFrame(data) {
      pushEvent("open3DsFrame");

      this.paymentIntentClientSecret = data.client_secret;
      this.stripe3DsSrc = data.next_action.redirect_to_url.url;
      this.show3DSDialog = true;
    },
    close3DsFrame() {
      pushEvent("close3DsFrame");

      this.show3DSDialog = false;
    },
    receiveMessage(event) {
      if (event.data === "3DS-authentication-complete") {
        this.process3DsAuth();
      }
    },
    auth3dsRedirectRequiredForData(data) {
      return !!(
        data &&
        data.next_action &&
        data.client_secret &&
        data.next_action.type &&
        data.next_action.type === "redirect_to_url" &&
        data.next_action.redirect_to_url &&
        data.next_action.redirect_to_url.url
      );
    },
    process3DsAuth() {
      this.close3DsFrame();

      if (this.paymentIntentClientSecret) {
        pushEvent("process3DsAuth");

        this.loadStripe()
          .then(() => {
            this.stripe
              .retrievePaymentIntent(this.paymentIntentClientSecret)
              .then((result) => {
                if (result.error) {
                  this.$emit("ThreeDsFailed", result.error);
                } else if (result.paymentIntent.status === "succeeded") {
                  this.$emit("ThreeDsSuccess");
                } else {
                  this.$emit("ThreeDsFailed", result);
                }
              });
          })
          .catch((error) => {
            pushEvent("3DsFailed");
            this.$emit("ThreeDsFailed", error);
          });
      }
    },
  },
};
